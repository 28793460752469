var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('mew-popup',{attrs:{"max-width":"380px","show":_vm.showBlock,"left-btn":{
      text: 'Go to block details',
      method: _vm.navToBlock,
      color: 'primary'
    },"right-btn":{
      text: 'Close',
      method: _vm.close,
      enabled: true
    },"has-buttons":false,"has-body-content":true}},[_c('h2',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.blockNumber))]),_c('div',{staticClass:"d-flex flex-column align-center justify-end py-5"},[_c('img',{attrs:{"src":_vm.img,"width":"300","height":"300"}})]),_c('div',[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c('div',{staticClass:"d-flex align-center justify-end mt-5"},[_c('mew-button',{attrs:{"btn-size":"large","btn-style":"transparent","title":"Go to block details","color-theme":"primary"},nativeOn:{"click":function($event){return _vm.navToBlock.apply(null, arguments)}}}),_c('mew-button',{staticClass:"ml-1",attrs:{"btn-size":"large","btn-style":"background","title":"Close","color-theme":"primary"},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }