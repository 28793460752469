<template>
  <!--
  ===================================================
    Loading indicator.
    Used in Mint.
  ===================================================
  -->
  <div min-height="148" class="d-flex align-content-center">
    <v-progress-circular
      indeterminate
      color="primary"
      class="ma-auto"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'BlocksLoading'
};
</script>

<style lang="scss" scoped>
.staked-status-container {
  max-width: 500px;
}
.border-container {
  border: 1px solid var(--v-selectBorder-base);
}
</style>
